// @flow

import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Analytics from '../utils/analytics'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PaddedContent from '../components/PaddedContent'
import Nav from '../components/Nav'
import { type FluidWithWebp } from '../types'
import { Header } from '../styles/pages/404'

type Props = {
  location: any,
  data: {
    headingImage: {
      image: FluidWithWebp,
    },
  },
}

class NotFoundPage extends React.Component<Props, *> {
  componentDidMount() {
    Analytics.pageview()
  }

  render() {
    const { location, data } = this.props

    return (
      <Layout>
        <SEO title="Not Found" location={location} />
        <Header>
          <Header.Background />
          <Nav location={location} />
          <PaddedContent>
            <Header.Title>Oops! Looks like that page doesn&apos;t exist.</Header.Title>
            <Img fluid={data.headingImage.image.fluid} alt="" />
          </PaddedContent>
        </Header>
      </Layout>
    )
  }
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    headingImage: file(relativePath: { eq: "styles/pages/assets/404.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 1082, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
