// @flow
import styled from '@emotion/styled'
import * as Fonts from '../fonts'
import { media } from '../dimensions'
import Colors from '../colors'

export const Header = styled.div`
  position: relative;
  padding-bottom: 48px;

  ${media.tablet`
    padding-bottom: 128px;
  `};
`

Header.Background = styled.div`
  background: ${Colors.secondary};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

Header.Title = styled.h1`
  ${Fonts.Title};
  position: relative;
  color: ${Colors.white};
  text-align: center;
  margin-bottom: 24px;

  ${media.tablet`
    margin: 0 auto 56px;
    width: 70%
  `};
`
